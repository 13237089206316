import React, { useCallback, useRef } from "react"
import styled from "styled-components"
import dynamic from "next/dynamic"
import Link from "next/link"
import Image from "next/image"
import { Flex } from "components/layout/shared"
import { NAMDEVCO_COMPANY_ID } from "lib/constants"
const OwlCarousel = dynamic(import("react-owl-carousel"), { ssr: false })

export default function LeaderboardSection({ leaderboardCategories }) {
  return (
    <Container className="arrivals container">
      <div className="row banner-row">
        <TitleSection align="center" className="col-md-4 banner-4">
          <Title>Welcome to a better way to shop.</Title>
        </TitleSection>
        <div className="col-md-8 banner-8">
          <div className="carousel slide" data-ride="carousel">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              dots={false}
              autoplay
              autoplayTimeout={4000}
              responsive={{
                0: {
                  items: 1,
                  nav: false,
                  dots: false,
                  loop: true,
                },
                600: {
                  items: 2,
                  nav: false,
                  dots: false,
                  loop: true,
                },
                1000: {
                  items: 2,
                  nav: true,
                  loop: true,
                  navText: [
                    '<img src="/left.png" className="d-block" />',
                    '<img src="/right.png" className="d-block" />',
                  ],
                },
              }}
            >
              <>
                {/* <a target="_blank" href="https://www.youtube.com/watch?v=t9KYn-cfKnE">
                                            <ImageContainer>
                                                <Image
                                                    src={"/unqueue-presents.jpg"}
                                                    alt={"unqueue-presents"}
                                                    objectFit="cover"
                                                    layout="fill"
                                                />
                                            </ImageContainer>
                                        </a> */}
                {leaderboardCategories.map((item, index) => (
                  <Link
                    key={index}
                    href={
                      item.id === "NpNNFcP0ZvSvjeD00GnC"
                        ? `/store/namdevco-farmers-market-pos`
                        : `/search?categoryId=${item.id}&title=${item.title}`
                    }
                  >
                    <a>
                      <ImageContainer>
                        <Image
                          src={item.image}
                          alt={item.title}
                          objectFit="cover"
                          layout="fill"
                        />
                      </ImageContainer>
                    </a>
                  </Link>
                ))}
              </>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </Container>
  )
}
const Container = styled.div`
  padding: 20px 0;
  @media (max-width: 550px) {
    padding: 0;
  }
`
const Title = styled.h3`
  font-weight: 300;
  font-size: 34px;
  text-align: left;
  color: white;
`
const ImageContainer = styled.div`
  height: 160px;
  cursor: pointer;
`

const TitleSection = styled(Flex)`
  @media (max-width: 768px) {
    display: none;
  }
`
