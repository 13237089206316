import mixpanel from "mixpanel-browser"
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
const development = process.env.NODE_ENV === "development"

export const _trackEvent = (event, params) => {
  if (development) {
    return
  }
  mixpanel.track(event, params)
}

export const TRACKING_EVENTS = {
  STORE_VISITED: "Store Visited",
  PRODUCT_ADDED_TO_CART: "Shopper Product Added To Cart",
  TRACKING_PAGE_VIEWED: "Shopper Visited Order Tracking",
  BOOK_RESERVE_BUTTON_CLICKED: "Book Reserve Button Clicked",
  SUCCESSFUL_BOOK_RESERVE: "Book Reserved Successfully",
  CLICKED_GLOBAL_SEARCH_FROM_STORE_SEARCH:
    "Shopper Clicked Global Search From Store Search",
  CLICKED_ORDER_TRACKING_FROM_RECEIPT:
    "Shopper Clicked Order Tracking From Receipt",
}
